import * as THREE from "three";
import {
  CSS2DObject,
} from "three/examples/jsm/renderers/CSS2DRenderer";
var Dbclick = function () {
  var label=null;
  var raycaster=null;
    this.fireRaycaster= function (scene,pointer,camera) {
      // 使用一个新的原点和方向来更新射线
      if(raycaster == null)
      {
         raycaster= new THREE.Raycaster();
      }
      
      raycaster.setFromCamera(pointer, camera);
      let intersects = raycaster.intersectObjects(
        scene.children,
        true
      );
    
      if (intersects.length > 0) {
        let selectedObject = intersects[0];
        console.log("########### dblclick:", selectedObject);
        return selectedObject;
      } else {
        return false;
      }
    };
}
export { Dbclick };
